import React from 'react'
import styles from './ElevateSection.module.scss'

const ElevateSection = ({ title, subtitle }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.layout}>
          <div className={styles.header}>
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>

          <div className={styles.pointer}>
            <img
              src="https://assets-global.website-files.com/61d72a2cda50bc679e28766b/64ae77622275a2d625fe68e0_pointer.svg"
              loading="lazy"
              alt="pointer"
              className={styles.arrowPointer}
            />
            <div>No more endless file versions</div>
          </div>

          <img
            src="/images/highlight-blueish.png"
            loading="eager"
            width="600"
            sizes="100vw"
            alt="blueish highlight"
            className={styles.elevateGradient}
          ></img>
          <img
            src="/images/file-versions.jpg"
            alt="Endless file versions"
            className={styles.mainImage}
          />
        </div>
      </div>
    </div>
  )
}

export default ElevateSection
