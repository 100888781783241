import React from 'react'
import styles from './ContentSection.module.scss'

const ContentSection = ({ video, reverse, centered, icon, children }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.layout}>
          <div
            className={`${styles.row} ${reverse && styles.reverse} ${centered &&
              styles.centered}`}
          >
            <div className={styles.left}>
              <div>
                {icon && (
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: icon.color }}
                  >
                    <img src={icon.src} />
                  </div>
                )}

                {children}
              </div>
            </div>
            {video && (
              <div className={`${styles.right} ${styles.reverse}`}>
                <div
                  className={`${styles.imageContainer} ${centered &&
                    styles.centered}`}
                >
                  <video
                    src={video}
                    loop
                    autoPlay
                    muted
                    playsInline
                    playsinline="true"
                    webkit-playsinline="true"
                    x5-playsinline="true"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 0,
                      display: 'block',
                      objectFit: 'cover',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      objectPosition: '50% 50%'
                    }}
                  ></video>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentSection
