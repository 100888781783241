import React from 'react'
import styles from './Spotlight.module.scss'
import Button from '../common/Button'

const Spotlight = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>Showcase</h2>
          <p>See how other teams are using Atlas</p>
          <Button variant="outline" href="/community/maps/">
            Explore examples
          </Button>
        </div>
        <div className={styles.grid}>
          <div>
            <img
              src="/images/example-01.jpg"
              alt="Landcover map"
              className={styles.gridImage}
            />
          </div>
          <div>
            <img
              src="/images/example-02.jpg"
              alt="3D Buildings"
              className={styles.gridImage}
            />
          </div>
          <div>
            <img
              src="/images/example-03.jpg"
              alt="Marathon map"
              className={styles.gridImage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spotlight
