import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from './Button'
import Fade from 'react-reveal/Fade'
import { PlayIcon } from '@heroicons/react/20/solid'

const PageHeader = ({ title, description, video }) => {
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    // Initial check
    handleResize()

    // Attach the event listeners
    window.addEventListener('resize', handleResize)

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const videoRef = useRef()

  const handleFullscreenClick = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    } else if (videoRef.current.mozRequestFullScreen) {
      // Firefox
      videoRef.current.mozRequestFullScreen()
    } else if (videoRef.current.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      videoRef.current.webkitRequestFullscreen()
    } else if (videoRef.current.msRequestFullscreen) {
      // IE/Edge
      videoRef.current.msRequestFullscreen()
    } else if (videoRef.current.webkitEnterFullscreen) {
      // iOS Safari
      videoRef.current.webkitEnterFullscreen()
    }
  }

  return (
    <>
      {open && (
        <Modal
          opened={open}
          onClose={setOpen}
          size="100%"
          withCloseButton={false}
          radius="lg"
        >
          <video
            src="/videos/website-hero.mp4"
            loop
            autoPlay
            muted
            playsinline
            controls
            style={{
              cursor: 'auto',
              width: '100%',
              height: '100%',
              borderRadius: 0,
              display: 'block',
              objectFit: 'cover',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              objectPosition: '50% 50%'
            }}
          ></video>
        </Modal>
      )}
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <Fade up duration={1000} delay={0} distance="30px" cascade>
              <h1 className={styles.title}>{title}</h1>
            </Fade>
            <div className={styles.descriptionContainer}>
              <div>
                <p>{description}</p>
              </div>
            </div>
          </div>
          {video && (
            <div
              className={styles.heroPromo}
              onClick={() =>
                !isMobile ? setOpen(true) : handleFullscreenClick()
              }
            >
              <video
                src={video}
                loop
                autoPlay
                muted
                playsInline
                playsinline="true"
                webkit-playsinline="true"
                x5-playsinline="true"
                ref={videoRef}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  display: 'block',
                  objectFit: 'cover',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  objectPosition: '50% 50%'
                }}
              ></video>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
