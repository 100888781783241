import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const WidgetsPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Visualize millions of data points with ease. Create and share interactive dashboards that take your data storytelling to the next level.',
        title: 'Widgets | Atlas',
        type: 'website'
      }}
      title={'Widgets | Atlas'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Build interactive dashboards'}
            description={
              'Visualize millions of data points with ease. Create and share interactive dashboards that take your data storytelling to the next level.'
            }
            video="https://cdn.atlas.co/landing-pages/widgets-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>Enable a richer interaction with your data</h2>
            <p>
              Create interactive dashboards that allow users to explore data in
              a more engaging way. Our widgets are designed to be easy to use
              and highly customizable.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/categories-widget.mp4'}
            icon={{ src: '/icons/categories.svg', color: '#FFF4E7' }}
          >
            <h3>Summarize and compare categorical data</h3>
            <p>
              The Category widget summarize and compare categorical data using
              proportional bar lengths to represent values. The longer the
              horizontal bar, the greater the value it represents.
            </p>
            <p>
              Use this widget to compare the distribution of data across a set
              of categories. It is particularly useful for comparing the size of
              a category to the total.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/range-widget.mp4'}
            reverse
            icon={{ src: '/icons/range.svg', color: '#CDE4E1' }}
          >
            <h3>Dynamic data filtering</h3>
            <p>
              The Range widget allows you to filter data based on a specific set
              of criteria. This is useful when you want to focus on a specific
              subset of data.
            </p>
            <p>
              Filter based on viewport bounds, numerical ranges, or any other
              data in your dataset.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/timeline-widget.mp4'}
            icon={{
              src: '/icons/timeline.svg',
              color: '#FFBF6E'
            }}
          >
            <h3>Identify patterns over time</h3>
            <p>
              The Time Series widget allows you to visualize data over time, and
              is particularly useful for identifying trends and patterns.
            </p>
            <p>
              Use this widget to compare data points over time, and to identify
              relationships between variables. It is particularly useful for
              time series data.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>
      </main>
    </Layout>
  )
}

export default WidgetsPage
